/*
 * Copyright 2016 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * CSS for FirebaseUI Javascript widget.
 * All selectors should begin with a "firebaseui-" prefix to avoid conflicts.
 */
.firebaseui-container {
    background-color: #fff;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87);
    direction: ltr;
    font: 16px Roboto, arial, sans-serif;
    margin: 0 auto;
    max-width: 360px;
    /** This is needed for the invisible reCAPTCHA badge to show. */
    overflow: visible;
    position: relative;
    text-align: left;
    width: 100%;
}

.firebaseui-container.mdl-card {
    /** This is needed for the invisible reCAPTCHA badge to show. */
    overflow: visible;
}

.firebaseui-card-header {
    padding: 24px 24px 0 24px;
}


.firebaseui-card-content {
    padding: 0 24px;
}

.firebaseui-card-footer {
    padding: 0 24px;
}

.firebaseui-card-actions {
    box-sizing: border-box;
    display: table;
    font-size: 14px;
    padding: 8px 24px 24px 24px;
    text-align: left;
    width: 100%;
}

.firebaseui-form-links {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}

.firebaseui-form-actions {
    display: table-cell;
    text-align: right;
    white-space: nowrap;
    width: 100%;
}

.firebaseui-title,
.firebaseui-subtitle {
    color: rgba(0, 0, 0, 0.87);
    direction: ltr;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    padding: 0;
    text-align: left;
}

.firebaseui-title {
    padding-bottom: 16px;
}

.firebaseui-subtitle {
    margin: 16px 0;
}

.firebaseui-text {
    color: rgba(0, 0, 0, 0.87);
    direction: ltr;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
}

.firebaseui-id-page-password-recovery-email-sent p.firebaseui-text {
    margin: 16px 0;
}

.firebaseui-text-emphasis {
    font-weight: 700;
}

.firebaseui-error {
    color: #dd2c00;
    direction: ltr;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    text-align: left;
}

.firebaseui-text-input-error {
    /* Set a negative margin, as MDL text inputs have a large bottom margin. */
    margin: -16px 0 16px;
}


.firebaseui-error-wrapper {
    min-height: 16px;
}


.firebaseui-list-item {
    direction: ltr;
    margin: 0;
    padding: 0;
    text-align: left;
}

.firebaseui-hidden {
    display: none;
}

.firebaseui-relative-wrapper {
    position: relative;
}

.firebaseui-label {
    color: rgba(0, 0, 0, 0.54);
    direction: ltr;
    font-size: 16px;
    text-align: left;
}

/* Override MDL floating text field label color. */
.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
    color: #757575;
}

.firebaseui-input,
.firebaseui-input-invalid {
    border-radius: 0; /** iOS puts rounded corners on text inputs. */
    color: rgba(0, 0, 0, 0.87);
    direction: ltr;
    font-size: 16px;
    width: 100%;
}

input.firebaseui-input,
input.firebaseui-input-invalid{
    direction: ltr;
    text-align: left;
}

.firebaseui-input-invalid {
    border-color: #dd2c00;
}

.firebaseui-textfield {
    width: 100%;
}

/* Override MDL's error handling with our own.  */
.firebaseui-textfield.mdl-textfield .firebaseui-input {
    border-color: rgba(0, 0, 0, 0.12);
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
    background-color: #3f51b5;
}

.firebaseui-textfield-invalid.mdl-textfield .firebaseui-input {
    border-color: #dd2c00;
}

.firebaseui-textfield-invalid.mdl-textfield .firebaseui-label::after {
    background-color: #dd2c00;
}

.firebaseui-button {
    display: inline-block;
    height: 36px;
    margin-left: 8px;
    min-width: 88px;
}

.firebaseui-link {
    color: #4285f4;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
}

.firebaseui-link:hover {
    text-decoration: underline;
}

.firebaseui-indent {
    margin-left: 1em;
}

.firebaseui-tos {
    color: #757575;
    direction: ltr;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 24px;
    margin-top: 0;
    text-align: left;
}

.firebaseui-provider-sign-in-footer > .firebaseui-tos  {
    text-align: center;
}

.firebaseui-tos-list {
    list-style: none;
    text-align: right;
}

.firebaseui-inline-list-item {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}

.firebaseui-page-provider-sign-in,
.firebaseui-page-select-tenant
{
    background: inherit;
}

.firebaseui-idp-list,
.firebaseui-tenant-list {
    list-style: none;
    margin: 1em 0;
    padding: 0;
}

.firebaseui-idp-button,
.firebaseui-tenant-button {
    direction: ltr;
    font-weight: 500;
    height: auto;
    line-height: normal;
    max-width: 220px;
    min-height: 40px;
    padding: 8px 16px;
    text-align: left;
    width: 100%;
}

.firebaseui-idp-list > .firebaseui-list-item,
.firebaseui-tenant-list > .firebaseui-list-item {
    margin-bottom: 15px;
    text-align: center;
}

.firebaseui-idp-icon-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.firebaseui-idp-icon {
    border: none;
    display: inline-block;
    height: 18px;
    vertical-align: middle;
    width: 18px;
}

.firebaseui-idp-favicon {
    border: none;
    display: inline-block;
    height: 14px;
    margin-right: 5px;
    vertical-align: middle;
    width: 14px;
}

.firebaseui-idp-text {
    color: #fff;
    display: table-cell;
    font-size: 14px;
    padding-left: 16px;
    text-transform: none;
    vertical-align: middle;
}

.firebaseui-idp-text.firebaseui-idp-text-long {
    display: table-cell;
}

.firebaseui-idp-text.firebaseui-idp-text-short {
    display: none;
}

/* 220px for button + 2x24 for margin = 268px */
@media (max-width: 268px) {
    .firebaseui-idp-text.firebaseui-idp-text-long {
        display: none;
    }

    .firebaseui-idp-text.firebaseui-idp-text-short {
        display: table-cell;
    }
}

/* Resize reCAPTCHA container on narrow mobile devices. */
@media (max-width: 320px) {
    .firebaseui-recaptcha-container > div > div {
        transform: scale(0.9);
        -webkit-transform: scale(0.9);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}

.firebaseui-idp-google > .firebaseui-idp-text {
    color: #757575;
}

/* Override the size of Yahoo's icon. */
[data-provider-id="yahoo.com"] > .firebaseui-idp-icon-wrapper >
.firebaseui-idp-icon {
    height: 22px;
    width: 22px;
}

.firebaseui-info-bar {
    background-color: #f9edbe;
    border: 1px solid #f0c36d;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    left: 10%;
    padding: 8px 16px;
    position: absolute;
    right: 10%;
    text-align: center;
    top: 0;
}

.firebaseui-info-bar-message {
    font-size: 12px;
    margin: 0;
}

.firebaseui-dialog {
    box-sizing: border-box;
    /* We need to set these properties again since dialogs are not contained in
     * the main FirebaseUI container. */
    color: rgba(0, 0, 0, 0.87);
    font: 16px Roboto, arial, sans-serif;
    /* If we do not set height here, the default Safari dialog settings make the
     * dialog too tall. */
    height: auto;
    padding: 24px;
    text-align: left;
}

.firebaseui-dialog-icon-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.firebaseui-dialog-icon {
    float: left;
    height: 40px;
    margin-right: 24px;
    width: 40px;
}

.firebaseui-progress-dialog-message {
    display: table-cell;
    font-size: 16px;
    font-weight: 400;
    min-height: 40px;
    vertical-align: middle;
}

.firebaseui-progress-dialog-loading-icon {
    height: 28px;
    margin: 6px 30px 6px 6px;
    width: 28px;
}

.firebaseui-icon-done {
    background-image: url("https://www.gstatic.com/images/icons/material/system/2x/done_googgreen_36dp.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 36px 36px;
}

.firebaseui-phone-number {
    display: flex;
}

.firebaseui-country-selector {
    /* TODO: Use 2x for HD displays. */
    background-image: url('https://www.gstatic.com/images/icons/material/system/1x/arrow_drop_down_grey600_18dp.png');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 18px auto;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.87);
    flex-shrink: 0;
    font-size: 16px;
    font-weight: normal;
    height: initial;
    line-height: normal;
    margin: 20px 24px 20px 0;
    padding: 4px 20px 4px 0;
    width: 90px;
}

.firebaseui-country-selector-flag {
    display: inline-block;
    margin-right: 1ex;
}

.firebaseui-flag {
    background-image: url("https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/flags_sprite_2x.png");
    background-size: 100% auto;
    /* Set a drop shadow so white flags are more distinct. */
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.54));
    height: 14px;
    width: 24px;
}

.firebaseui-list-box-dialog {
    max-height: 90%;
    overflow: auto;
    padding: 8px 0 0 0;
}

.firebaseui-list-box-actions {
    padding-bottom: 8px;
}

.firebaseui-list-box-icon-wrapper {
    display: table-cell;
    padding-right: 24px;
    vertical-align: top;
}

.firebaseui-list-box-label-wrapper {
    display: table-cell;
    vertical-align: top;
}

.firebaseui-list-box-dialog-button {
    color: rgba(0, 0, 0, 0.87);
    direction: ltr;
    font-size: 16px;
    font-weight: normal;
    height: initial;
    line-height: normal;
    min-height: 48px;
    padding: 14px 24px;
    text-align: left;
    text-transform: none;
    width: 100%;
}

.firebaseui-phone-number-error {
    /* Align this with the national number input. Add a margin of
     * button width (90px) + button margin (24px). */
    margin-left: 114px;
}

.mdl-progress.firebaseui-busy-indicator {
    height: 2px;
    left: 0;
    position: absolute;
    top: 55px;
    width: 100%;
}

.mdl-spinner.firebaseui-busy-indicator {
    direction: initial;
    height: 56px;
    left: 0px;
    margin: auto;
    position: absolute;
    right: 0px;
    top: 30%;
    width: 56px;
}

.firebaseui-callback-indicator-container .firebaseui-busy-indicator {
    top: 0px;
}

.firebaseui-callback-indicator-container {
    height: 120px;
}

.firebaseui-new-password-component {
    display: inline-block;
    position: relative;
    width: 100%;
}

.firebaseui-input-floating-button {
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    height: 24px;
    position: absolute;
    right: 0;
    top: 20px;
    width: 24px;
}

.firebaseui-input-toggle-on {
    background-image: url("https://www.gstatic.com/images/icons/material/system/1x/visibility_black_24dp.png");
}

.firebaseui-input-toggle-off {
    background-image: url("https://www.gstatic.com/images/icons/material/system/1x/visibility_off_black_24dp.png");
}

.firebaseui-input-toggle-focus {
    opacity: 0.87;
}

.firebaseui-input-toggle-blur {
    opacity: 0.38;
}

.firebaseui-recaptcha-wrapper {
    display: table;
    margin: 0 auto;
    padding-bottom: 8px;
}

.firebaseui-recaptcha-container {
    display: table-cell;
}

.firebaseui-recaptcha-error-wrapper {
    caption-side: bottom;
    display: table-caption;
}

.firebaseui-change-phone-number-link {
    display: block;
}

.firebaseui-resend-container {
    direction: ltr;
    margin: 20px 0;
    text-align: center;
}

.firebaseui-id-resend-countdown {
    color: rgba(0, 0, 0, 0.38);
}

/**
 * This div holds the invisible reCAPTCHA badge. The float property is needed
 * as it messes up the cancel and submit buttons to align vertically when they
 * should horizontally.
 */
.firebaseui-id-page-phone-sign-in-start .firebaseui-form-actions div {
    float: left;
}

@media (max-width: 480px) {
    .firebaseui-container {
        box-shadow: none;
        max-width: none;
        width: 100%;
    }

    .firebaseui-card-header {
        border-bottom: 1px solid #e0e0e0;
        margin-bottom: 16px;
        padding: 16px 24px 0 24px;
    }

    .firebaseui-title {
        padding-bottom: 16px;
    }

    .firebaseui-card-actions {
        padding-right: 24px;
    }

    .firebaseui-busy-indicator {
        top: 0px;
    }
}

/*
 * Bootstrap applies styles to all <label> elements, so override those styles.
 */
.mdl-textfield__label {
    font-weight: normal;
    margin-bottom: 0;
}

.firebaseui-id-page-blank {
    background: inherit;
    height: 64px;
}

.firebaseui-id-page-spinner {
    background: inherit;
    height: 64px;
}

.firebaseui-email-sent {
    background-image: url("https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/success_status.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 64px 64px;
    height: 64px;
    margin-top: 16px;
    text-align: center;
}

.firebaseui-text-justify {
    text-align: justify;
}
